import React from 'react'
import { Typography, Box, Grid } from '@hermes/web-components'
import ArrowRightIcon from '../../Icons/ArrowRightIcon'

type FaqItemProps = {
  title: React.ReactNode
  mainDescription: React.ReactNode
  additionalDescription?: React.ReactNode
}

const FaqItem = ({ title, mainDescription, additionalDescription }: FaqItemProps) => (
  <Box sx={{ mt: 4 }}>
    <Grid container alignItems="center" sx={{ mb: 1 }} wrap="nowrap">
      <Grid item display="flex" alignItems="center">
        <ArrowRightIcon reflectIfRtl sx={{ width: '16px', height: '16px' }} />
      </Grid>
      <Grid item>
        <Typography
          variant="body2"
          textTransform="uppercase"
          gutterBottom
          color="text.darkNavy"
          sx={{ ml: 1.25, mb: 0 }}
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
    <Typography color="text.black900" variant="body1" whiteSpace="break-spaces">
      {mainDescription}
    </Typography>
    {additionalDescription && (
      <Typography color="text.black900" variant="body1" whiteSpace="break-spaces">
        {additionalDescription}
      </Typography>
    )}
  </Box>
)

export default FaqItem
