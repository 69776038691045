import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

const StarDocumentIcon = (props: SvgIconProps) => (
  <SvgIcon width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.21429 17.3926C1.89224 17.3926 1.58338 17.2647 1.35566 17.037C1.12793 16.8093 1 16.5004 1 16.1784V2.82122C1 2.49917 1.12793 2.19031 1.35566 1.96259C1.58338 1.73487 1.89224 1.60693 2.21429 1.60693H15.5714C15.8935 1.60693 16.2023 1.73487 16.4301 1.96259C16.6578 2.19031 16.7857 2.49917 16.7857 2.82122V16.1784C16.7857 16.5004 16.6578 16.8093 16.4301 17.037C16.2023 17.2647 15.8935 17.3926 15.5714 17.3926"
      stroke="#051851"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1 5.25H16.7857" stroke="#051851" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8.22501 9.94932C8.27728 9.81468 8.36903 9.69899 8.48821 9.61741C8.60739 9.53584 8.74844 9.49219 8.89287 9.49219C9.03729 9.49219 9.17835 9.53584 9.29753 9.61741C9.41671 9.69899 9.50845 9.81468 9.56073 9.94932L10.3379 11.9286H12.4143C12.5501 11.939 12.6802 11.9872 12.79 12.0677C12.8998 12.1483 12.9848 12.258 13.0353 12.3845C13.0859 12.5109 13.1 12.649 13.0761 12.783C13.0522 12.9171 12.9912 13.0417 12.9 13.1429L11.0664 14.7943L11.8436 16.3486C11.9139 16.4851 11.9378 16.6408 11.9115 16.792C11.8852 16.9433 11.8102 17.0818 11.6979 17.1865C11.5864 17.2925 11.4446 17.3611 11.2923 17.3829C11.1399 17.4047 10.9846 17.3784 10.8479 17.3079L8.89287 16.2393L6.93787 17.3079C6.80113 17.3784 6.6458 17.4047 6.49348 17.3829C6.34115 17.3611 6.19939 17.2925 6.08787 17.1865C5.97558 17.0818 5.90058 16.9433 5.87427 16.792C5.84797 16.6408 5.87181 16.4851 5.94215 16.3486L6.7193 14.7943L4.88573 13.1429C4.79455 13.0417 4.73355 12.9171 4.70963 12.783C4.68571 12.649 4.69984 12.5109 4.75041 12.3845C4.80099 12.258 4.88598 12.1483 4.99574 12.0677C5.10551 11.9872 5.23567 11.939 5.37144 11.9286H7.44787L8.22501 9.94932Z"
      stroke="#051851"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)

export { StarDocumentIcon }
