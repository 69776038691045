import React from 'react'
import { SvgIconProps } from '../../components/SvgIcon'

const ArrowRightTop = (props: SvgIconProps) => (
  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.98709 1.99711L1.98709 2.0004L9.88547 2.00747L1.27998 10.613C0.889455 11.0035 0.889455 11.6367 1.27998 12.0272C1.6705 12.4177 2.30367 12.4177 2.69419 12.0272L11.2997 3.42169L11.3068 11.3201H11.3094C11.3005 11.5862 11.3975 11.8551 11.6007 12.0582C11.9898 12.4474 12.6207 12.4474 13.0098 12.0582C13.2146 11.8534 13.3116 11.5817 13.3008 11.3134L13.3008 0.00636038L2.03557 0.00636036C1.76022 -0.0107931 1.4791 0.0858392 1.26868 0.296257C0.879569 0.685372 0.879569 1.31625 1.26868 1.70537C1.46676 1.90345 1.72749 2.00069 1.98709 1.99711Z"
      fill="#1A3586"
    />
  </svg>
)

export default ArrowRightTop
