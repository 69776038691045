import React from 'react'
import { Typography, Grid, Chip, Image } from '@hermes/web-components'
import dayjs from 'dayjs'
import { PATH_TO_UNAVAILABLE_IMG } from 'apps/public/hermes-web/constants/global'
import { ContentWrapper, ImageWrapper, PostCardContent, PostCardWrapper } from './styles'
import { SharedLinkData } from '../../../../../types'

type PostCardProps = {
  horizontal?: boolean
  onClick?: () => void
} & SharedLinkData

function getWebsiteName(url: string) {
  try {
    const parsedUrl = new URL(url)
    const { hostname } = parsedUrl
    return hostname.replace(/^www\./, '')
  } catch (e) {
    return 'Doctify'
  }
}

const PostCard = ({
  title,
  description,
  link,
  image,
  date,
  createdAt,
  keywords,
  duration,
  horizontal = false,
  onClick
}: PostCardProps) => (
  <PostCardWrapper target="_blank" href={link} onClick={onClick} className={horizontal ? 'first-article' : ''}>
    <ContentWrapper horizontal={horizontal}>
      <ImageWrapper horizontal={horizontal}>
        <Image lazy src={image || PATH_TO_UNAVAILABLE_IMG} errorSrc={PATH_TO_UNAVAILABLE_IMG} alt={title} />
      </ImageWrapper>
      <Grid container alignContent="space-between">
        <Grid item>
          <PostCardContent
            container
            flexDirection="column"
            gap="7px"
            horizontal={horizontal}
            sx={{
              textDecoration: 'none'
            }}
          >
            <Grid item>
              <Typography color="common.white" sx={{ wordBreak: 'break-all' }} component="h4" fontSize="16px">
                {title}
              </Typography>
            </Grid>
            {description && (
              <Grid item>
                <Typography fontSize="14px" lineHeight="19.6px" color="common.white">
                  {description}
                </Typography>
              </Grid>
            )}
          </PostCardContent>
          {!!keywords && !!keywords.length && (
            <Grid container alignItems="center" mt="26px" gap="8px">
              {keywords.map((keyword, index) => (
                <Chip
                  sx={{ contentVisibility: 'auto', containIntrinsicHeight: '600px' }}
                  key={`video-card-keyword-${index}`}
                  label={keyword.title}
                  variant="blue300"
                />
              ))}
            </Grid>
          )}
        </Grid>
        <Grid mt="24px" container wrap="nowrap" justifyContent="space-between">
          <Grid container flexDirection="row" wrap="nowrap" gap="10px">
            {/* <HeartIcon */}
            {/*  sx={{ */}
            {/*    fill: enabled ? 'white' : 'none', */}
            {/*    width: '17px', */}
            {/*    height: '16px' */}
            {/*  }} */}
            {/* /> */}
            {duration && (
              <Typography variant="body2" lineHeight="20px" color="common.white">
                {duration}
              </Typography>
            )}
            <Typography variant="body2" lineHeight="20px" color="common.white">
              {dayjs(date || createdAt).format('DD MMMM YYYY')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" lineHeight="20px" color="common.white" textTransform="capitalize">
              {getWebsiteName(link) || 'doctify'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </ContentWrapper>
  </PostCardWrapper>
)

export default PostCard
