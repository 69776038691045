import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

const NotepadIcon = (props: SvgIconProps) => (
  <SvgIcon width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.6429 2.82129H12.5714C12.9124 2.82129 13.2394 2.94922 13.4806 3.17695C13.7217 3.40467 13.8571 3.71353 13.8571 4.03557V16.1784C13.8571 16.5005 13.7217 16.8093 13.4806 17.0371C13.2394 17.2648 12.9124 17.3927 12.5714 17.3927H2.28571C1.94472 17.3927 1.6177 17.2648 1.37658 17.0371C1.13546 16.8093 1 16.5005 1 16.1784V4.03557C1 3.71353 1.13546 3.40467 1.37658 3.17695C1.6177 2.94922 1.94472 2.82129 2.28571 2.82129H4.21429"
      stroke="#051851"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.35715 1.60693H5.50001C4.78993 1.60693 4.21429 2.15059 4.21429 2.82122V3.42836C4.21429 4.09899 4.78993 4.64265 5.50001 4.64265H9.35715C10.0672 4.64265 10.6429 4.09899 10.6429 3.42836V2.82122C10.6429 2.15059 10.0672 1.60693 9.35715 1.60693Z"
      stroke="#051851"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.21429 7.67822H10.6429"
      stroke="#051851"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.21429 10.7139H10.6429"
      stroke="#051851"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4.21429 13.75H10.6429" stroke="#051851" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
)

export { NotepadIcon }
