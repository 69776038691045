const MOCKED_PINED_FACILITIES = [
  'Wheelchair Accessible',
  '155 Beds',
  'Lift',
  'Emergency Care',
  'Wifi',
  'Paediatric services',
  'Financing available',
  'lorem ipsi dolar',
  'Emergency'
]

const MOCKED_SELECTED_FACILITIES = [
  'Wheelchair Accessible',
  '155 Beds',
  'Lift',
  'Emergency Care',
  'Wifi',
  'Paediatric services',
  'Financing available',
  'lorem ipsi dolar',
  'Emergency',
  'Daycare',
  'Family room'
]

export { MOCKED_PINED_FACILITIES, MOCKED_SELECTED_FACILITIES }
