import { PracticeProfile } from '../../types/practice'

export default function getAddressSchema(address: PracticeProfile['address'], lang: string) {
  return {
    '@type': 'PostalAddress',
    addressCountry: address.country[lang],
    addressLocality: address.city[lang],
    postalCode: address.postcode[lang],
    streetAddress: [address.street1, address.street2]
      .map((i) => i?.[lang])
      .filter((i) => !!i)
      .join(' ')
  }
}
