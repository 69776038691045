import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

const ShiledManIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.46432 6.92878C7.97324 6.92878 9.19646 5.6336 9.19646 4.03592C9.19646 2.43824 7.97324 1.14307 6.46432 1.14307C4.9554 1.14307 3.73218 2.43824 3.73218 4.03592C3.73218 5.6336 4.9554 6.92878 6.46432 6.92878Z"
      stroke="#051851"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.6786 8.99854C6.87789 8.80281 6.04576 8.80126 5.2444 8.994C4.44305 9.18675 3.69321 9.5688 3.05094 10.1116C2.40868 10.6544 1.89061 11.3439 1.53546 12.1286C1.18031 12.9132 0.997271 13.7727 1.00003 14.6428V16.5714H7.6786"
      stroke="#051851"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3493 17.8186C13.2159 17.8697 13.0698 17.8697 12.9365 17.8186C11.9238 17.3939 11.0548 16.657 10.4421 15.7034C9.82946 14.7498 9.50126 13.6234 9.50002 12.4701V10.7858C9.49466 10.6999 9.50668 10.6138 9.5353 10.5332C9.56391 10.4526 9.60845 10.3795 9.66594 10.3186C9.72342 10.2577 9.79253 10.2106 9.86862 10.1803C9.94472 10.15 10.026 10.1372 10.1072 10.1429H16.1786C16.2597 10.1372 16.341 10.15 16.4171 10.1803C16.4932 10.2106 16.5623 10.2577 16.6198 10.3186C16.6773 10.3795 16.7219 10.4526 16.7505 10.5332C16.7791 10.6138 16.7911 10.6999 16.7857 10.7858V12.4701C16.7845 13.6234 16.4563 14.7498 15.8436 15.7034C15.2309 16.657 14.362 17.3939 13.3493 17.8186Z"
      stroke="#051851"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)

export { ShiledManIcon }
