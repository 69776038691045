import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

const MedalWithStarIcon = (props: SvgIconProps) => (
  <SvgIcon width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="6.38867" cy="7.22363" r="5.63867" stroke="#051851" strokeWidth="1.5" />
    <path
      d="M5.9697 9.32045L4.49528 10.0768L4.77366 8.49309C4.82588 8.19601 4.7241 7.89583 4.50791 7.69022L3.34007 6.57946L4.96563 6.34898C5.25723 6.30764 5.51508 6.12804 5.65024 5.86081L6.38866 4.4009L7.12707 5.86081C7.26224 6.12804 7.52009 6.30764 7.81169 6.34898L9.43724 6.57946L8.2694 7.69022C8.05322 7.89583 7.95144 8.19601 8.00366 8.49309L8.28203 10.0768L6.80762 9.32045C6.54493 9.1857 6.23238 9.1857 5.9697 9.32045Z"
      stroke="#051851"
    />
    <path
      d="M2.9043 11.6367V19.0217C2.9043 19.4578 3.42357 19.6849 3.74375 19.3888L6.04921 17.257C6.24084 17.0798 6.53651 17.0798 6.72813 17.257L9.03359 19.3888C9.35377 19.6849 9.87305 19.4578 9.87305 19.0217V11.6367"
      stroke="#051851"
      strokeWidth="1.5"
    />
  </SvgIcon>
)

export { MedalWithStarIcon }
