import React, { useRef, useEffect } from 'react'
import type { PropsWithChildren, HTMLAttributes } from 'react'
import { styled } from '@mui/system'
import laztLoadImages from '../../../src/utils/lazyLoadImages'

const SlotRoot = styled('span')<{ inline?: boolean }>(({ inline, theme }) => ({
  display: inline ? 'inline' : 'block',
  color: theme.palette.text.black900,
  fontSize: 14,
  b: {
    color: theme.palette.text.darkNavy,
    fontSize: '13.33px'
  }
}))

export type HTMLSlotProps = PropsWithChildren<{
  inline?: boolean
  lazyImages?: boolean
  className?: string
  classes?: {
    [key: string]: object
  }
}> &
  HTMLAttributes<HTMLSpanElement>

function HTMLSlot({ children, className, inline, lazyImages, ...restProps }: HTMLSlotProps) {
  const wrapper = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if (!wrapper.current) {
      return
    }

    if (lazyImages) {
      laztLoadImages(wrapper.current)
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children])

  return children ? (
    <SlotRoot
      {...restProps}
      inline={inline}
      ref={wrapper}
      className={className}
      dangerouslySetInnerHTML={{ __html: children as string }}
    />
  ) : null
}

HTMLSlot.defaultProps = {
  inline: false,
  lazyImages: false,
  className: '',
  classes: {}
}

export default HTMLSlot
