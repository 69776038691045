import { Box, styledComponent } from '@hermes/web-components'

export const SocialsUnavailable = styledComponent(Box, {
  shouldForwardProp: (prop: string) => prop !== 'articlesAvailable'
})<{ articlesAvailable: boolean }>(({ theme, articlesAvailable }) => ({
  position: 'relative',
  backgroundColor: theme.palette.common.white,
  paddingBottom: '100px',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: '-200px',
    width: '200px',
    height: '200px',
    borderBottomLeftRadius: '100px',
    boxShadow: `0 100px 0 0 ${theme.palette.common.white}`
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    right: 0,
    top: '-100',
    width: '200px',
    height: '200px',
    borderTopRightRadius: '100px',
    boxShadow: `0 -100px 0 0 ${articlesAvailable ? theme.palette.blue[900] : theme.palette.primary.dark}`
  }
}))
