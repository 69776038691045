import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

const HeartPulseProfileIcon = (props: SvgIconProps) => (
  <SvgIcon width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.11166 4.90971C1.83257 1.62558 6.1156 -0.844193 10.1866 3.52129C15.9963 -2.76661 22.2017 5.02986 17.9893 9.04824L10.1866 15.8434L5.62086 11.8384"
      stroke="#051851"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 8.50157H4.53385L6.65417 5.83154L9.48125 10.5041L11.6016 7.83406H13.7219"
      stroke="#051851"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)

export { HeartPulseProfileIcon }
