import { styledComponent, Box, Grid } from '@hermes/web-components'

export const IntroductionVideoWrapper = styledComponent(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '8px',
  height: '411px',
  overflow: 'hidden',
  border: '1.5px solid transparent',
  '&:hover': {
    backgroundColor: theme.palette.system.white400,
    boxShadow:
      '0px 54px 82px rgba(2, 3, 3, 0.03), 0px 22px 98px rgba(2, 3, 3, 0.02), 0px 23px 28px rgba(2, 3, 3, 0.04)',
    border: '1.5px solid #EAEEFC'
  }
}))

export const ContentWrapper = styledComponent(Grid)({
  flexWrap: 'nowrap',
  padding: '19px 16px',
  paddingTop: '20%',
  justifyContent: 'space-between',
  gap: '50px',
  background: 'linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(13, 12, 14, 0.52) 76.56%)',
  '@media (max-width: 681px)': {
    flexWrap: 'wrap',
    gap: '10px'
  }
})
