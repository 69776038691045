import React, { FC } from 'react'

export type DonutChartItem = {
  value: number
  name: string
  color: string
}

export type DonutChartProps = {
  data: DonutChartItem[]
  width?: number
  height?: number
  thickness?: number
}

const DonutChart: FC<DonutChartProps> = ({ data, width = 200, height = 200, thickness = 3 }) => {
  const total = data.reduce((sum, item) => sum + item.value, 0)

  const calculateOffset = (index: number) => {
    if (index === 0) {
      return 0
    }
    return data.slice(0, index).reduce((sum, item) => sum + (item.value / total) * 100, 0)
  }

  return (
    <svg width={width} height={height} viewBox="0 0 42 42" className="donut">
      {data.map((item, index) => {
        const offset = calculateOffset(index)
        const valuePercentage = (item.value / total) * 100

        return (
          <circle
            key={`donut-segment-${item.name}`}
            className="donut-segment"
            cx="21"
            cy="21"
            r="15.91549431"
            fill="transparent"
            stroke={item.color}
            strokeWidth={thickness}
            strokeDasharray={`${valuePercentage} ${100 - valuePercentage}`}
            strokeDashoffset={-offset}
          />
        )
      })}
    </svg>
  )
}

export default DonutChart
