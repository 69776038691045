export const currencySymbol = (currency: string, language: string): string => {
  switch (currency) {
    case 'GBP':
      return '£'
    case 'USD':
      return '$'
    case 'EUR':
      return '€'
    case 'AED':
      return language === 'ar' ? 'د.إ' : 'AED '
    case 'AUD':
      return '$'
    default:
      return currency
  }
}
