import React, { useContext } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  ExpandMoreIcon,
  styled,
  Divider,
  Box
} from '@hermes/web-components'
import { currencySymbol } from '../../../utils/price'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import { AppDataContext } from '../../../providers/AppData'
import { HealthCheck } from '../../../types/index'

const List = styled('ul')(({ theme }) => ({
  // @ts-ignore
  ...theme.typography.body2,
  paddingLeft: theme.spacing(2),
  color: theme.palette.grey[800],
  textTransform: 'capitalize',
  margin: 0
}))

type PracticeHealthChecksProps = {
  healthChecks: HealthCheck[]
}

const groupByKeywordId = (groupedHealthChecks: HealthCheck[]) =>
  groupedHealthChecks.reduce((acc, healthCheck) => {
    const key = healthCheck.keywordId
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(healthCheck)
    return acc
  }, {} as Record<string, HealthCheck[]>)

const PracticeHealthChecks = ({ healthChecks }: PracticeHealthChecksProps) => {
  const translate = useTranslateMessage()
  const { language } = useContext(AppDataContext)

  const groupedHealthChecks = React.useMemo(() => Object.values(groupByKeywordId(healthChecks)), [healthChecks])

  return (
    <>
      {groupedHealthChecks.map((healthCheckGroup, idx) => {
        const currencyLabel = currencySymbol(healthCheckGroup[0].packageCurrency, language)

        return (
          <Accordion key={`accordion-${idx}`} sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body2">{healthCheckGroup[0].name[language]}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {healthCheckGroup.map((healthCheckItem, index) => (
                <Box key={`box-${index}`}>
                  <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: '9px', mb: '9px' }}>
                    <Grid item md={10} xs={10} container>
                      <Typography variant="body2" color="text.primary">
                        {healthCheckItem.packageName[language]}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body2" color="text.primary">
                        {`${currencyLabel}${healthCheckItem.packagePrice[0]} - ${currencyLabel}${healthCheckItem.packagePrice[1]}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  {healthCheckItem.packageIncludedFeatures[language]?.length ? (
                    <Grid>
                      <Typography variant="body2" color="text.secondary">
                        {translate('included')}
                      </Typography>
                      <List>
                        {healthCheckItem.packageIncludedFeatures[language]?.map((item, liIndex) => (
                          <li key={`li-${item}-${liIndex}`}>{item}</li>
                        ))}
                      </List>
                    </Grid>
                  ) : null}
                  {healthCheckItem.packageExcludedFeatures[language]?.length ? (
                    <Grid>
                      <Typography variant="body2" color="text.secondary">
                        {translate('excluded')}
                      </Typography>
                      <List>
                        {healthCheckItem.packageExcludedFeatures[language]?.map((item, liIndex) => (
                          <li key={`li-${item}-${liIndex}`}>{item}</li>
                        ))}
                      </List>
                    </Grid>
                  ) : null}
                  {healthCheckGroup.length - 1 > index ? <Divider sx={{ pt: 1 }} /> : null}
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        )
      })}
    </>
  )
}

export default PracticeHealthChecks
