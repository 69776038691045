import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

const PublicationsIcon = (props: SvgIconProps) => (
  <SvgIcon width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.15256 16.0699L5.07628 12.5759L1 16.0699V5.58799C1 5.27911 1.1227 4.98287 1.34112 4.76446C1.55953 4.54604 1.85577 4.42334 2.16465 4.42334H7.98791C8.29679 4.42334 8.59302 4.54604 8.81144 4.76446C9.02985 4.98287 9.15256 5.27911 9.15256 5.58799V16.0699Z"
      stroke="#051851"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.49377 0.929688H11.4817C11.7906 0.929688 12.0868 1.05239 12.3052 1.27081C12.5236 1.48922 12.6463 1.78545 12.6463 2.09434V12.5762"
      stroke="#051851"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)

export { PublicationsIcon }
