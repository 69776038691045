import React, { ReactNode, useMemo, useRef } from 'react'
import { Grid, Typography, useTheme, DonutChart } from '@hermes/web-components'
import ExpandableList from './ExapndableList'
import useTranslateMessage from '../hooks/useTranslateMessage'
import { Statistic } from '../types/statistics'
import { getProportion } from '../utils/numbers'

type AreasOfExpertiseChartProps = {
  statistic: Statistic[]
  language: string
  title?: ReactNode
  activeName?: string
}

const AreasOfExpertiseChart = ({ statistic = [], language, title }: AreasOfExpertiseChartProps) => {
  const defaultTheme = useTheme()
  const translate = useTranslateMessage()
  const colors = useRef([
    defaultTheme.palette.system.aqua,
    defaultTheme.palette.system.coral,
    defaultTheme.palette.system.lightBlue,
    '#2B59E0',
    '#CFD4DF',
    defaultTheme.palette.secondary.light
  ])
  const transformedStatistic = useMemo(() => {
    const sorted = [...statistic].sort((a, b) => b.tagCount - a.tagCount)
    const summ = sorted.reduce((prev, curr) => prev + curr.tagCount, 0)
    const allItems = sorted.map((stat, idx) => ({
      name: `${stat.tagName[language]} (${
        getProportion(summ, stat.tagCount, 0) > 1 ? getProportion(summ, stat.tagCount, 0) : '<1'
      }%)`,
      keyName: stat.tagName[language],
      value: stat.tagCount,
      isExpandButton: false,
      color: colors.current[idx] || colors.current[colors.current.length - 1]
    }))
    const othersCount = sorted.slice(5).reduce((prev, curr) => prev + curr.tagCount, 0)
    const topItems = allItems.slice(0, 5)

    if (othersCount) {
      const expandItems = {
        name: `${translate('others')} (${
          getProportion(summ, othersCount, 0) > 1 ? getProportion(summ, othersCount, 0) : '<1'
        }%)`,
        keyName: translate('others'),
        value: othersCount,
        isExpandButton: true,
        color: colors.current[colors.current.length - 1]
      }

      allItems.push(expandItems)
      topItems.push(expandItems)
    }

    return {
      topItems,
      allItems
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statistic])

  const donutChartTopItems = useMemo(
    () =>
      transformedStatistic.topItems.map((item) => ({
        value: item.value,
        name: item.keyName,
        color: String(item.color)
      })),
    [transformedStatistic.topItems]
  )

  return (
    <Grid
      container
      sx={(theme) => ({
        gap: '94px',
        flexWrap: 'nowrap',
        [theme.breakpoints.down('sm')]: {
          gap: '40px',
          flexDirection: 'column'
        }
      })}
    >
      <Grid item sm={7} xs={12}>
        {title}
        <Typography marginTop="4px" variant="body2" color="text.black900">
          {translate('profile.based_on_peer_recommendations_and_reviews')}
        </Typography>
        <ExpandableList
          noSpacing
          largeFont
          largeSpacing
          limit={5}
          items={transformedStatistic.allItems}
          valueKey="name"
        />
      </Grid>
      <Grid
        item
        sx={(theme) => ({
          marginRight: '24px',
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center'
          }
        })}
      >
        <DonutChart width={246} height={246} thickness={9} data={donutChartTopItems} />
      </Grid>
    </Grid>
  )
}

export default AreasOfExpertiseChart
