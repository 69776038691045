import { styledComponent, Box, Grid, Typography, Button } from '@hermes/web-components'

export const ProfilePostsWrapper = styledComponent(Box)(({ theme }) => ({
  '&.videos': {
    backgroundColor: theme.palette.primary.dark
  },
  '&.articles': {
    position: 'relative',
    backgroundColor: theme.palette.blue[900],
    paddingTop: '100px',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '-200px',
      width: '200px',
      height: '200px',
      zIndex: 0,
      borderBottomLeftRadius: '100px',
      boxShadow: `0 100px 0 0 ${theme.palette.blue[900]}`
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: '0',
      zIndex: 0,
      width: '200px',
      height: '200px',
      borderTopRightRadius: '100px',
      boxShadow: `0 -100px 0 0 ${theme.palette.primary.dark}`
    }
  }
}))

export const PostsWrapper = styledComponent(Grid)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  marginBottom: '100px',
  gap: '10px',
  '.introduction-video': {
    gridColumn: 'span 2'
  },
  '.first-article': {
    gridColumn: 'span 3'
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
    justifyContent: 'center',
    '.first-article': {
      gridColumn: 'span 2'
    }
  },
  [theme.breakpoints.down('sm')]: {
    '.introduction-video': {
      gridColumn: '1'
    },
    '.first-article': {
      gridColumn: '1'
    },
    gridTemplateColumns: '1fr'
  }
}))

export const PostsTitle = styledComponent(Typography)<{
  component: string
}>(({ theme }) => ({
  fontSize: '35px',
  lineHeight: '45.5px',
  fontWeight: 600,
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
    lineHeight: '31.2px'
  }
}))

export const SortByDateButton = styledComponent(Button)(({ theme }) => ({
  padding: 0,
  p: {
    fontSize: '14px',
    lineHeight: '19.6px'
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '25px',
    p: {
      ...theme.typography.body2,
      lineHeight: '20px'
    }
  }
}))
