import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

const MedalIcon = (props: SvgIconProps) => (
  <SvgIcon width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="6.38867" cy="7.22363" r="5.63867" stroke="#051851" strokeWidth="1.5" />
    <path
      d="M2.9043 11.6367V19.0217C2.9043 19.4578 3.42357 19.6849 3.74375 19.3888L6.04921 17.257C6.24084 17.0798 6.53651 17.0798 6.72813 17.257L9.03359 19.3888C9.35377 19.6849 9.87305 19.4578 9.87305 19.0217V11.6367"
      stroke="#051851"
      strokeWidth="1.5"
    />
  </SvgIcon>
)

export { MedalIcon }
