import { Grid, Typography, Button, Box, Image, Hidden } from '@hermes/web-components'
import React, { useContext, useState } from 'react'
import { useRouter } from 'next/router'
import { PATH_TO_UNAVAILABLE_IMG } from 'apps/public/hermes-web/constants/global'
import { ProfilePostsWrapper, SortByDateButton, PostsTitle, PostsWrapper } from './styles'
import { MediaContentWrapper } from '../Header/styles'
import Card from './Card'
import { SharedLinkData } from '../../../../types'
import IntroductionVideo from './IntroductionVideo'
import useTranslateMessage from '../../../../hooks/useTranslateMessage'
import CardSkeleton from './Card/skeleton'
import { ProfileContext } from '../../../../providers/AppData/Profile'
import { sendMediaStats } from '../../../../api/profile'

interface ProfilePostsProps {
  posts: SharedLinkData[]
  introductionVideo?: SharedLinkData
  setVideoPlayerVisibility?: (bool: boolean) => void
  className: 'videos' | 'articles'
  title: string
  subtitle: string
  sortByDateText: string
  loadMoreText: string
  triggerVideoAnalytics?: () => void
  triggerArticleAnalytics?: () => void
  triggerIntroVideoAnalytics?: () => void
}

const ProfilePosts = ({
  posts,
  introductionVideo,
  setVideoPlayerVisibility = () => null,
  className,
  title,
  subtitle,
  sortByDateText,
  loadMoreText,
  triggerVideoAnalytics,
  triggerArticleAnalytics,
  triggerIntroVideoAnalytics
}: ProfilePostsProps) => {
  const profile = useContext(ProfileContext)
  const practiceId = profile?.practice?.id
  const specialistId = profile?.specialist?.id
  const handleOpenVideoPlayer = () => setVideoPlayerVisibility(true)
  const amountPerLoad = 6
  const [sortedData, setSortedData] = useState<SharedLinkData[]>(
    posts.slice(0, className === 'videos' && !introductionVideo ? 6 : 4)
  )
  const [sortByDateEnabled, setSortByDateEnabled] = useState(false)
  const translate = useTranslateMessage()
  const postsAvailable = Boolean(!posts || !!posts.length)
  const router = useRouter()
  const locale = router.locale || router.defaultLocale!

  const sortByDate = (videos: SharedLinkData[]) =>
    videos.sort((a, b) => {
      const dateA = Date.parse(a.date || a.createdAt)
      const dateB = Date.parse(b.date || b.createdAt)
      return dateB - dateA
    })

  const handleSortByDate = () => {
    setSortedData((prevState) => sortByDate([...prevState]))
    setSortByDateEnabled(true)
  }

  const loadMore = () => {
    const moreVideos = posts.slice(sortedData.length, sortedData.length + amountPerLoad)
    if (sortByDateEnabled) {
      setSortedData((prevState) => [...prevState, ...sortByDate(moreVideos)])
    } else {
      setSortedData((prevState) => [...prevState, ...moreVideos])
    }
  }

  const handleAnalytics = (mediaId: number) => {
    if (triggerVideoAnalytics) {
      triggerVideoAnalytics()
    }
    if (triggerArticleAnalytics) {
      triggerArticleAnalytics()
    }
    sendMediaStats({
      mediaId,
      practiceId,
      specialistId,
      locale
    })
  }

  const handleClickIntroVideo = () => {
    if (triggerIntroVideoAnalytics) {
      triggerIntroVideoAnalytics()
    }
    handleOpenVideoPlayer()
    if (introductionVideo?.id) {
      sendMediaStats({
        mediaId: introductionVideo?.id,
        practiceId,
        specialistId,
        locale
      })
    }
  }

  return (
    <section id={className}>
      <ProfilePostsWrapper className={className}>
        <MediaContentWrapper display="grid">
          <Grid container justifyContent="space-between" gap="8px" mb="24px" alignItems="end">
            <Grid item color="common.white">
              <PostsTitle component="h3">
                {!postsAvailable && className === 'articles'
                  ? translate('media_center.articles.unavailable_title')
                  : title}
              </PostsTitle>
              <Typography maxWidth="560px" fontSize="14px" lineHeight="19.6px">
                {!postsAvailable && className === 'articles' ? '' : subtitle}
              </Typography>
            </Grid>
            <Grid item>
              {postsAvailable && (
                <>
                  {posts.length > 4 && (
                    <SortByDateButton onClick={handleSortByDate} variant="ghost">
                      <Typography color="common.white">{sortByDateText}</Typography>
                    </SortByDateButton>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          <PostsWrapper>
            {introductionVideo && introductionVideo.enabled && (
              <Box className="introduction-video" onClick={handleClickIntroVideo} sx={{ cursor: 'pointer' }}>
                <IntroductionVideo {...introductionVideo} />
              </Box>
            )}
            {sortedData.map(
              (post, index) =>
                post?.enabled && (
                  <Card
                    onClick={() => handleAnalytics(post.id)}
                    key={`profile-${className}-${post.id}`}
                    horizontal={index === 0 && className === 'articles' && sortedData.length > 3}
                    {...post}
                  />
                )
            )}
            {!introductionVideo && !postsAvailable && className === 'videos' && (
              <>
                <Box
                  borderRadius="4px"
                  maxWidth="665px"
                  width="100%"
                  className="introduction-video"
                  bgcolor="blue.400"
                  p="37px 50px"
                >
                  <Typography fontSize="24px" fontWeight="600" mb="1.41">
                    {translate('media_center.videos.unavailable_title')}
                  </Typography>
                  <Typography fontSize="14px">{translate('media_center.videos.unavailable_subtitle')}</Typography>
                </Box>
                <Hidden mdDown>
                  <Image
                    sx={{
                      borderRadius: '4px',
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%'
                    }}
                    lazy
                    src={PATH_TO_UNAVAILABLE_IMG}
                    alt={title}
                  />
                </Hidden>
              </>
            )}
            {!postsAvailable &&
              className === 'articles' &&
              Array(3)
                .fill(1)
                .map((_, index) => <CardSkeleton key={`card-skeleton-${index}`} />)}
          </PostsWrapper>
          {sortedData.length !== posts.length && (
            <Grid container justifyContent="center" mb="100px">
              <Button
                sx={{
                  borderColor: 'common.white',
                  color: 'common.white',
                  borderRadius: '32px',
                  padding: '8px 24px',
                  position: 'relative',
                  zIndex: 1
                }}
                onClick={loadMore}
                variant="outlined"
              >
                {loadMoreText}
              </Button>
            </Grid>
          )}
        </MediaContentWrapper>
      </ProfilePostsWrapper>
    </section>
  )
}

export default ProfilePosts
