import React from 'react'

const EducationIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.21429 16.8929C1.89224 16.8929 1.58338 16.7649 1.35566 16.5372C1.12793 16.3095 1 16.0006 1 15.6786V2.32143C1 1.99938 1.12793 1.69053 1.35566 1.4628C1.58338 1.23508 1.89224 1.10715 2.21429 1.10715H15.5714C15.8935 1.10715 16.2023 1.23508 16.4301 1.4628C16.6578 1.69053 16.7857 1.99938 16.7857 2.32143V15.6786C16.7857 16.0006 16.6578 16.3095 16.4301 16.5372C16.2023 16.7649 15.8935 16.8929 15.5714 16.8929"
      stroke="#051851"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1 4.75H16.7857" stroke="#051851" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8.22495 9.44928C8.27722 9.31465 8.36897 9.19896 8.48815 9.11738C8.60733 9.03581 8.74838 8.99216 8.89281 8.99216C9.03723 8.99216 9.17829 9.03581 9.29747 9.11738C9.41665 9.19896 9.50839 9.31465 9.56067 9.44928L10.3378 11.4286H12.4142C12.55 11.4389 12.6802 11.4871 12.7899 11.5677C12.8997 11.6483 12.9847 11.758 13.0353 11.8844C13.0858 12.0109 13.1 12.1489 13.076 12.283C13.0521 12.4171 12.9911 12.5417 12.9 12.6429L11.0664 14.2943L11.8435 15.8486C11.9139 15.985 11.9377 16.1407 11.9114 16.292C11.8851 16.4432 11.8101 16.5817 11.6978 16.6864C11.5863 16.7924 11.4445 16.8611 11.2922 16.8829C11.1399 16.9046 10.9846 16.8784 10.8478 16.8079L8.89281 15.7393L6.93781 16.8079C6.80106 16.8784 6.64574 16.9046 6.49342 16.8829C6.34109 16.8611 6.19933 16.7924 6.08781 16.6864C5.97552 16.5817 5.90052 16.4432 5.87421 16.292C5.84791 16.1407 5.87175 15.985 5.94209 15.8486L6.71924 14.2943L4.88567 12.6429C4.79449 12.5417 4.73349 12.4171 4.70957 12.283C4.68565 12.1489 4.69978 12.0109 4.75035 11.8844C4.80092 11.758 4.88591 11.6483 4.99568 11.5677C5.10545 11.4871 5.23561 11.4389 5.37138 11.4286H7.44781L8.22495 9.44928Z"
      stroke="#051851"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default EducationIcon
