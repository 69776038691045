import React from 'react'

const FacilitiesAtGlanceIcon = () => (
  <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_10439_4322" fill="white">
      <rect x="2.45508" y="0.295898" width="13.0909" height="5.93939" rx="1" />
    </mask>
    <rect
      x="2.45508"
      y="0.295898"
      width="13.0909"
      height="5.93939"
      rx="1"
      stroke="#051851"
      strokeWidth="2.4"
      mask="url(#path-1-inside-1_10439_4322)"
    />
    <path
      d="M0.4 14.2957C0.4 14.6271 0.668629 14.8957 1 14.8957C1.33137 14.8957 1.6 14.6271 1.6 14.2957H0.4ZM1.13789 8.6167L0.620616 8.31267L1.13789 8.6167ZM2.76845 4.65837L0.620616 8.31267L1.65515 8.92072L3.80298 5.26643L2.76845 4.65837ZM0.4 9.12341V14.2957H1.6V9.12341H0.4ZM0.620616 8.31267C0.476165 8.55843 0.4 8.83833 0.4 9.12341H1.6C1.6 9.05214 1.61904 8.98216 1.65515 8.92072L0.620616 8.31267Z"
      fill="#051851"
    />
    <path
      d="M16.4006 14.2957C16.4006 14.6271 16.6692 14.8957 17.0006 14.8957C17.3319 14.8957 17.6006 14.6271 17.6006 14.2957H16.4006ZM16.8718 8.79187L16.3491 9.08633L16.8718 8.79187ZM14.1921 5.25686L16.3491 9.08633L17.3946 8.49741L15.2376 4.66794L14.1921 5.25686ZM16.4006 9.28264V14.2957H17.6006V9.28264H16.4006ZM16.3491 9.08633C16.3828 9.14625 16.4006 9.21386 16.4006 9.28264H17.6006C17.6006 9.00754 17.5296 8.73709 17.3946 8.49741L16.3491 9.08633Z"
      fill="#051851"
    />
    <path
      d="M5.36328 5.4929V3.76563C5.36328 3.48948 5.58714 3.26562 5.86328 3.26562H8.99964M12.636 5.4929V3.76562C12.636 3.48948 12.4122 3.26562 12.136 3.26562H8.99964M8.99964 3.26562V5.4929"
      stroke="#051851"
      strokeWidth="1.2"
    />
    <path d="M1 9.62939H17M1 13.1294H17" stroke="#051851" strokeWidth="1.2" />
  </svg>
)

export default FacilitiesAtGlanceIcon
