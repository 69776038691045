import { styledComponent, DonutChart } from '@hermes/web-components'
import { RevealsContainer } from '../profiles/ProfileModal/SpecialistPracticeBookingCard'

export const StyledRevealsContainer = styledComponent(RevealsContainer)(({ theme }) => ({
  '& > button, & > a': {
    flex: 1
  },
  padding: '16px',
  [theme.breakpoints.up('lg')]: {
    borderTop: `1px solid ${theme.palette.grey[600]}`
  },
  [theme.breakpoints.down('sm')]: {
    borderTop: `1px solid ${theme.palette.grey[600]}`,
    position: 'fixed',
    bottom: 0,
    zIndex: 9,
    left: 0,
    backgroundColor: theme.palette.common.white
  }
}))

export const DonutChartContainer = styledComponent(DonutChart)({
  '&.donut': {
    minWidth: 120,
    minHeight: 120,
    padding: '10px',
    width: 100,
    height: 100,
    marginLeft: '-16px'
  }
})
