import { Box, styledComponent, Grid, Link } from '@hermes/web-components'
import { PostsWrapper } from '../Posts/styles'

export const ProfileSocialsWrapper = styledComponent(Box, {
  shouldForwardProp: (prop: string) => prop !== 'articlesAvailable'
})<{ articlesAvailable: boolean }>(({ theme, articlesAvailable }) => ({
  backgroundColor: theme.palette.blue[400],
  position: 'relative',
  padding: '100px 0 50px 0',
  [theme.breakpoints.down('sm')]: {
    padding: '40px 0 10px 0'
  },
  '& > *': {
    position: 'relative',
    zIndex: 1
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    top: '-200px',
    width: '200px',
    height: '200px',
    borderBottomLeftRadius: '100px',
    boxShadow: `0 100px 0 0 ${theme.palette.blue[400]}`,
    zIndex: 0
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    right: 0,
    top: '0',
    width: '200px',
    height: '200px',
    borderTopRightRadius: '100px',
    boxShadow: `0 -100px 0 0 ${articlesAvailable ? theme.palette.blue[900] : theme.palette.primary.dark}`,
    zIndex: 0
  }
}))

export const SocialComponentWrapper = styledComponent(Box, {
  shouldForwardProp: (prop: string) => prop !== 'isLightVariant'
})<{ isLightVariant: boolean }>(({ isLightVariant }) => ({
  maxWidth: '311px',
  width: '100%',
  height: '114px',
  position: 'relative',
  borderRadius: '16px',
  background: isLightVariant ? '#557AE6' : '#112971',
  '&:hover': {
    background: isLightVariant ? '#112971' : '#557AE6'
  }
}))

export const SocialsTitle = styledComponent(Grid)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '50px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '20px'
  }
}))

export const WebsiteLink = styledComponent(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  zIndex: 1,
  textDecoration: 'none',
  p: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '22.4px',
    color: theme.palette.text.darkNavy
  }
}))

export const SocialsWrapper = styledComponent(PostsWrapper)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginBottom: '50px'
  }
}))
