import { Box, Grid } from '@hermes/web-components'

const CardSkeleton = () => (
  <Box
    sx={{ contentVisibility: 'auto', containIntrinsicHeight: '600px' }}
    bgcolor="blue.700"
    p="23px 20px"
    borderRadius="4px"
  >
    <Box bgcolor="blue.300" height="120px" width="100%" borderRadius="4px" />
    <Box mt="16px" bgcolor="blue.300" height="15px" width="100%" borderRadius="4px" />
    <Box mt="8px" bgcolor="blue.300" height="15px" width="90%" borderRadius="4px" />
    <Grid mt="16px" container gap="9px">
      <Box bgcolor="blue.500" height="21px" width="84px" borderRadius="20px" />
      <Box bgcolor="blue.500" height="21px" width="84px" borderRadius="20px" />
    </Grid>
    <Grid mt="28px" container wrap="nowrap" justifyContent="space-between">
      <Grid container gap="8px">
        <Box bgcolor="blue.300" height="10px" width="74px" borderRadius="4px" />
        <Box bgcolor="blue.300" height="10px" width="74px" borderRadius="4px" />
      </Grid>
      <Box bgcolor="blue.300" height="10px" width="74px" borderRadius="4px" />
    </Grid>
  </Box>
)

export default CardSkeleton
