import React from 'react'

type NoReviewsImageIconProps = {
  width?: string | number
  height?: string | number
}

const NoReviewsImageIcon = ({ width = '218', height = '113' }: NoReviewsImageIconProps) => (
  <svg width={width} height={height} viewBox="0 0 218 113" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="202.662" height="112.669" rx="6.23577" fill="#051851" />
    <rect width="202.662" height="112.669" rx="6.23577" fill="url(#paint0_linear_3886_28276)" fillOpacity="0.7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.6989 34.4498C42.3186 34.2547 41.8643 34.2547 41.484 34.4498L36.2804 37.1192C35.3228 37.6104 34.2036 36.817 34.3865 35.7765L35.3803 30.1227C35.4529 29.7095 35.3125 29.2879 35.0048 28.9953L30.795 24.9913C30.0203 24.2544 30.4478 22.9705 31.5185 22.8187L37.3363 21.9939C37.7615 21.9336 38.129 21.673 38.3191 21.2971L40.9209 16.1531C41.3997 15.2064 42.7832 15.2065 43.262 16.1531L45.8638 21.2971C46.054 21.673 46.4215 21.9336 46.8467 21.9939L52.6645 22.8187C53.7351 22.9705 54.1627 24.2544 53.3879 24.9913L49.1781 28.9953C48.8705 29.2879 48.7301 29.7095 48.8027 30.1227L49.7965 35.7765C49.9794 36.817 48.8601 37.6104 47.9025 37.1192L42.6989 34.4498Z"
      fill="#00E5D0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.9008 36.4145L41.1044 33.7452C41.7224 33.4282 42.4606 33.4282 43.0785 33.7452L48.2822 36.4145C48.6413 36.5988 49.061 36.3012 48.9924 35.911L47.9986 30.2572C47.8806 29.5858 48.1087 28.9008 48.6086 28.4253L52.8185 24.4212C53.109 24.1449 52.9487 23.6634 52.5472 23.6065L46.7293 22.7816C46.0385 22.6837 45.4412 22.2603 45.1322 21.6494L42.5304 16.5054C42.3509 16.1504 41.8321 16.1504 41.6525 16.5054L39.0507 21.6494C38.7417 22.2603 38.1445 22.6837 37.4536 22.7816L31.6358 23.6065C31.2343 23.6634 31.074 24.1449 31.3645 24.4212L35.5743 28.4253C36.0742 28.9008 36.3024 29.5858 36.1844 30.2572L35.1906 35.911C35.122 36.3012 35.5417 36.5988 35.9008 36.4145ZM41.484 34.4498C41.8643 34.2547 42.3186 34.2547 42.6989 34.4498L47.9025 37.1192C48.8601 37.6104 49.9794 36.817 49.7965 35.7765L48.8027 30.1227C48.7301 29.7095 48.8705 29.2879 49.1781 28.9953L53.3879 24.9913C54.1627 24.2544 53.7351 22.9705 52.6645 22.8187L46.8467 21.9939C46.4215 21.9336 46.054 21.673 45.8638 21.2971L43.262 16.1531C42.7832 15.2065 41.3997 15.2064 40.9209 16.1531L38.3191 21.2971C38.129 21.673 37.7615 21.9336 37.3363 21.9939L31.5185 22.8187C30.4478 22.9705 30.0203 24.2544 30.795 24.9913L35.0048 28.9953C35.3125 29.2879 35.4529 29.7095 35.3803 30.1227L34.3865 35.7765C34.2036 36.817 35.3228 37.6104 36.2804 37.1192L41.484 34.4498Z"
      fill="#008579"
      fillOpacity="0.6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.3188 34.4498C71.9385 34.2547 71.4842 34.2547 71.1039 34.4498L65.9003 37.1192C64.9427 37.6104 63.8234 36.817 64.0063 35.7765L65.0001 30.1227C65.0728 29.7095 64.9324 29.2879 64.6247 28.9953L60.4149 24.9913C59.6402 24.2544 60.0677 22.9705 61.1383 22.8187L66.9562 21.9939C67.3813 21.9336 67.7489 21.673 67.939 21.2971L70.5408 16.1531C71.0196 15.2064 72.4031 15.2065 72.8819 16.1531L75.4837 21.2971C75.6738 21.673 76.0414 21.9336 76.4665 21.9939L82.2844 22.8187C83.355 22.9705 83.7825 24.2544 83.0078 24.9913L78.798 28.9953C78.4903 29.2879 78.3499 29.7095 78.4226 30.1227L79.4164 35.7765C79.5993 36.817 78.48 37.6104 77.5224 37.1192L72.3188 34.4498Z"
      fill="#00E5D0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.5207 36.4145L70.7243 33.7452C71.3422 33.4282 72.0805 33.4282 72.6984 33.7452L77.902 36.4145C78.2611 36.5988 78.6809 36.3012 78.6123 35.911L77.6185 30.2572C77.5005 29.5858 77.7286 28.9008 78.2285 28.4253L82.4383 24.4212C82.7289 24.1449 82.5685 23.6634 82.167 23.6065L76.3492 22.7816C75.6583 22.6837 75.0611 22.2603 74.7521 21.6494L72.1503 16.5054C71.9707 16.1504 71.452 16.1504 71.2724 16.5054L68.6706 21.6494C68.3616 22.2603 67.7644 22.6837 67.0735 22.7816L61.2557 23.6065C60.8542 23.6634 60.6938 24.1449 60.9844 24.4212L65.1942 28.4253C65.6941 28.9008 65.9222 29.5858 65.8042 30.2572L64.8104 35.911C64.7418 36.3012 65.1616 36.5988 65.5207 36.4145ZM71.1039 34.4498C71.4842 34.2547 71.9385 34.2547 72.3188 34.4498L77.5224 37.1192C78.48 37.6104 79.5993 36.817 79.4164 35.7765L78.4226 30.1227C78.3499 29.7095 78.4903 29.2879 78.798 28.9953L83.0078 24.9913C83.7825 24.2544 83.355 22.9705 82.2844 22.8187L76.4665 21.9939C76.0414 21.9336 75.6738 21.673 75.4837 21.2971L72.8819 16.1531C72.4031 15.2065 71.0196 15.2064 70.5408 16.1531L67.939 21.2971C67.7489 21.673 67.3813 21.9336 66.9562 21.9939L61.1383 22.8187C60.0677 22.9705 59.6402 24.2544 60.4149 24.9913L64.6247 28.9953C64.9324 29.2879 65.0728 29.7095 65.0001 30.1227L64.0063 35.7765C63.8234 36.817 64.9427 37.6104 65.9003 37.1192L71.1039 34.4498Z"
      fill="#008579"
      fillOpacity="0.6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.939 34.4498C101.558 34.2547 101.104 34.2547 100.724 34.4498L95.5202 37.1192C94.5626 37.6104 93.4433 36.817 93.6262 35.7765L94.62 30.1227C94.6926 29.7095 94.5522 29.2879 94.2446 28.9953L90.0348 24.9913C89.26 24.2544 89.6876 22.9705 90.7582 22.8187L96.576 21.9939C97.0012 21.9336 97.3687 21.673 97.5589 21.2971L100.161 16.1531C100.639 15.2064 102.023 15.2065 102.502 16.1531L105.104 21.2971C105.294 21.673 105.661 21.9336 106.086 21.9939L111.904 22.8187C112.975 22.9705 113.402 24.2544 112.628 24.9913L108.418 28.9953C108.11 29.2879 107.97 29.7095 108.042 30.1227L109.036 35.7765C109.219 36.817 108.1 37.6104 107.142 37.1192L101.939 34.4498Z"
      fill="#00E5D0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.1405 36.4145L100.344 33.7452C100.962 33.4282 101.7 33.4282 102.318 33.7452L107.522 36.4145C107.881 36.5988 108.301 36.3012 108.232 35.911L107.238 30.2572C107.12 29.5858 107.348 28.9008 107.848 28.4253L112.058 24.4212C112.349 24.1449 112.188 23.6634 111.787 23.6065L105.969 22.7816C105.278 22.6837 104.681 22.2603 104.372 21.6494L101.77 16.5054C101.591 16.1504 101.072 16.1504 100.892 16.5054L98.2905 21.6494C97.9815 22.2603 97.3842 22.6837 96.6933 22.7816L90.8755 23.6065C90.474 23.6634 90.3137 24.1449 90.6042 24.4212L94.8141 28.4253C95.314 28.9008 95.5421 29.5858 95.4241 30.2572L94.4303 35.911C94.3617 36.3012 94.7814 36.5988 95.1405 36.4145ZM100.724 34.4498C101.104 34.2547 101.558 34.2547 101.939 34.4498L107.142 37.1192C108.1 37.6104 109.219 36.817 109.036 35.7765L108.042 30.1227C107.97 29.7095 108.11 29.2879 108.418 28.9953L112.628 24.9913C113.402 24.2544 112.975 22.9705 111.904 22.8187L106.086 21.9939C105.661 21.9336 105.294 21.673 105.104 21.2971L102.502 16.1531C102.023 15.2065 100.639 15.2064 100.161 16.1531L97.5589 21.2971C97.3687 21.673 97.0012 21.9336 96.576 21.9939L90.7582 22.8187C89.6876 22.9705 89.26 24.2544 90.0348 24.9913L94.2446 28.9953C94.5522 29.2879 94.6926 29.7095 94.62 30.1227L93.6262 35.7765C93.4433 36.817 94.5626 37.6104 95.5202 37.1192L100.724 34.4498Z"
      fill="#008579"
      fillOpacity="0.6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M131.559 34.4498C131.178 34.2547 130.724 34.2547 130.344 34.4498L125.14 37.1192C124.182 37.6104 123.063 36.817 123.246 35.7765L124.24 30.1227C124.313 29.7095 124.172 29.2879 123.864 28.9953L119.655 24.9913C118.88 24.2544 119.307 22.9705 120.378 22.8187L126.196 21.9939C126.621 21.9336 126.989 21.673 127.179 21.2971L129.781 16.1531C130.259 15.2064 131.643 15.2065 132.122 16.1531L134.723 21.2971C134.914 21.673 135.281 21.9336 135.706 21.9939L141.524 22.8187C142.595 22.9705 143.022 24.2544 142.248 24.9913L138.038 28.9953C137.73 29.2879 137.59 29.7095 137.662 30.1227L138.656 35.7765C138.839 36.817 137.72 37.6104 136.762 37.1192L131.559 34.4498Z"
      fill="#00E5D0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M124.76 36.4145L129.964 33.7452C130.582 33.4282 131.32 33.4282 131.938 33.7452L137.142 36.4145C137.501 36.5988 137.921 36.3012 137.852 35.911L136.858 30.2572C136.74 29.5858 136.968 28.9008 137.468 28.4253L141.678 24.4212C141.969 24.1449 141.808 23.6634 141.407 23.6065L135.589 22.7816C134.898 22.6837 134.301 22.2603 133.992 21.6494L131.39 16.5054C131.21 16.1504 130.692 16.1504 130.512 16.5054L127.91 21.6494C127.601 22.2603 127.004 22.6837 126.313 22.7816L120.495 23.6065C120.094 23.6634 119.934 24.1449 120.224 24.4212L124.434 28.4253C124.934 28.9008 125.162 29.5858 125.044 30.2572L124.05 35.911C123.982 36.3012 124.401 36.5988 124.76 36.4145ZM130.344 34.4498C130.724 34.2547 131.178 34.2547 131.559 34.4498L136.762 37.1192C137.72 37.6104 138.839 36.817 138.656 35.7765L137.662 30.1227C137.59 29.7095 137.73 29.2879 138.038 28.9953L142.248 24.9913C143.022 24.2544 142.595 22.9705 141.524 22.8187L135.706 21.9939C135.281 21.9336 134.914 21.673 134.723 21.2971L132.122 16.1531C131.643 15.2065 130.259 15.2064 129.781 16.1531L127.179 21.2971C126.989 21.673 126.621 21.9336 126.196 21.9939L120.378 22.8187C119.307 22.9705 118.88 24.2544 119.655 24.9913L123.864 28.9953C124.172 29.2879 124.313 29.7095 124.24 30.1227L123.246 35.7765C123.063 36.817 124.182 37.6104 125.14 37.1192L130.344 34.4498Z"
      fill="#008579"
      fillOpacity="0.6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M161.178 34.4498C160.798 34.2547 160.344 34.2547 159.964 34.4498L154.76 37.1192C153.802 37.6104 152.683 36.817 152.866 35.7765L153.86 30.1227C153.932 29.7095 153.792 29.2879 153.484 28.9953L149.275 24.9913C148.5 24.2544 148.927 22.9705 149.998 22.8187L155.816 21.9939C156.241 21.9336 156.608 21.673 156.799 21.2971L159.4 16.1531C159.879 15.2064 161.263 15.2065 161.742 16.1531L164.343 21.2971C164.533 21.673 164.901 21.9336 165.326 21.9939L171.144 22.8187C172.215 22.9705 172.642 24.2544 171.867 24.9913L167.658 28.9953C167.35 29.2879 167.21 29.7095 167.282 30.1227L168.276 35.7765C168.459 36.817 167.34 37.6104 166.382 37.1192L161.178 34.4498Z"
      fill="#8794AE"
    />
    <rect x="28.2529" y="50.0002" width="146.157" height="4.90706" rx="2.45353" fill="#406AE3" />
    <rect x="44.1357" y="60.8489" width="114.391" height="4.906" rx="2.453" fill="#406AE3" />
    <path
      d="M0.779471 79.0055H201.883V106.58C201.883 109.593 199.44 112.036 196.427 112.036H6.23575C3.22233 112.036 0.779471 109.593 0.779471 106.58V79.0055Z"
      fill="#F4F7FD"
    />
    <path
      d="M94.2176 99.0898C95.3068 99.0898 96.2984 98.6145 96.6886 97.9492V98.963H98.4605V87.747H96.6886V91.9768C96.2009 91.3114 95.2418 90.852 94.1851 90.852C91.7141 90.852 90.2998 92.5629 90.2998 94.9709C90.2998 97.3789 91.7141 99.0898 94.2176 99.0898ZM94.4452 97.5848C93.0146 97.5848 92.0718 96.5392 92.0718 94.9709C92.0718 93.3867 93.0146 92.357 94.4452 92.357C95.8758 92.357 96.8186 93.3867 96.8186 94.9709C96.8186 96.5392 95.8758 97.5848 94.4452 97.5848Z"
      fill="#112971"
    />
    <path
      d="M103.248 99.0898C105.703 99.0898 107.475 97.363 107.475 94.9709C107.475 92.5788 105.703 90.852 103.248 90.852C100.777 90.852 99.0212 92.5788 99.0212 94.9709C99.0212 97.363 100.777 99.0898 103.248 99.0898ZM103.248 97.5848C101.785 97.5848 100.809 96.5076 100.809 94.9709C100.809 93.4342 101.785 92.357 103.248 92.357C104.695 92.357 105.686 93.4342 105.686 94.9709C105.686 96.5076 104.695 97.5848 103.248 97.5848Z"
      fill="#112971"
    />
    <path
      d="M112.136 99.0898C114.136 99.0898 115.55 98.0917 115.891 96.4283L114.152 96.0957C113.957 97.0303 113.225 97.5848 112.185 97.5848C110.787 97.5848 109.86 96.5551 109.86 94.9709C109.86 93.3867 110.771 92.357 112.185 92.357C113.225 92.357 113.989 92.9748 114.152 93.8778L115.875 93.5451C115.599 91.8976 114.152 90.852 112.136 90.852C109.681 90.852 108.023 92.5154 108.023 94.9709C108.023 97.4264 109.681 99.0898 112.136 99.0898Z"
      fill="#112971"
    />
    <path
      d="M121.367 98.8997L121.254 97.363C120.945 97.5056 120.571 97.5531 120.262 97.5531C119.595 97.5531 119.14 97.1412 119.14 96.3491V92.452H121.254V90.9787H119.14V88.6024H117.368V90.9787H115.938V92.452H117.368V96.4442C117.368 98.266 118.522 99.0898 120.067 99.0898C120.522 99.0898 120.977 99.0264 121.367 98.8997Z"
      fill="#112971"
    />
    <path
      d="M123.089 89.648C123.756 89.648 124.26 89.1569 124.26 88.5391C124.26 87.9054 123.756 87.4143 123.089 87.4143C122.439 87.4143 121.951 87.9054 121.951 88.5391C121.951 89.1569 122.439 89.648 123.089 89.648ZM122.212 98.963H123.984V90.9787H122.212V98.963Z"
      fill="#112971"
    />
    <path
      d="M126.199 98.963H127.971V92.452H129.97V90.9787H127.971V90.2658C127.971 89.4738 128.426 88.9668 129.125 88.9668C129.401 88.9668 129.678 89.0143 129.97 89.1252L130.068 87.6678C129.71 87.5569 129.304 87.4935 128.898 87.4935C127.337 87.4935 126.199 88.4916 126.199 90.2975V90.9787H124.85V92.452H126.199V98.963Z"
      fill="#112971"
    />
    <path
      d="M135.383 90.9787L133.188 96.5076L131.042 90.9787H129.124L132.311 98.7254L131.075 101.846H132.896L137.204 90.9787H135.383Z"
      fill="#112971"
    />
    <path
      d="M82.132 87.551C79.1741 87.2415 77.7609 89.6783 76.1983 90.169C76.0213 90.1843 75.8388 90.1857 75.6521 90.1815C75.6604 90.1843 75.6687 90.1885 75.677 90.1913C77.3087 90.3321 79.3511 89.4552 81.5014 91.2257C85.3595 94.4013 82.7003 100.408 75.9715 102.805C76.4113 102.959 76.869 103.099 77.3433 103.221C78.2629 103.458 79.2516 103.235 80.0315 102.69C88.345 96.8645 88.2233 88.1909 82.132 87.551Z"
      fill="#A7F3D8"
    />
    <path
      d="M82.132 87.551C79.1741 87.2415 77.7609 89.6783 76.1983 90.169C76.0213 90.1843 75.8388 90.1857 75.6521 90.1815C75.6604 90.1843 75.6687 90.1885 75.677 90.1913C77.3087 90.3321 79.3511 89.4552 81.5014 91.2257C85.3595 94.4013 82.7003 100.408 75.9715 102.805C76.4113 102.959 76.869 103.099 77.3433 103.221C78.2629 103.458 79.2516 103.235 80.0315 102.69C88.345 96.8645 88.2233 88.1909 82.132 87.551Z"
      fill="url(#paint1_radial_3886_28276)"
    />
    <path
      d="M69.8358 87.551C72.7936 87.2415 74.2069 89.6783 75.7695 90.169C75.9465 90.1843 76.129 90.1857 76.3157 90.1815C76.3074 90.1843 76.2991 90.1885 76.2908 90.1913C74.6591 90.3321 72.6166 89.4552 70.4664 91.2257C66.6083 94.4013 69.2675 100.408 75.9962 102.805C75.5565 102.959 75.0988 103.099 74.6245 103.221C73.7049 103.458 72.7162 103.235 71.9363 102.69C63.6228 96.8645 63.7445 88.1909 69.8358 87.551Z"
      fill="url(#paint2_radial_3886_28276)"
    />
    <path
      d="M75.9723 102.804C75.516 102.965 75.0375 103.114 74.5383 103.245C69.9391 101.091 67.4334 97.2212 68.6351 94.2422C68.2299 97.3523 71.0038 101.059 75.9723 102.804Z"
      fill="url(#paint3_linear_3886_28276)"
    />
    <path
      d="M81.5013 91.2255C79.3511 89.455 77.3086 90.3319 75.6769 90.1911C75.6686 90.1883 75.6603 90.1841 75.652 90.1813C74.1212 90.1479 72.2904 89.6251 70.3738 91.2045C66.5047 94.3913 69.1943 100.425 75.9701 102.804C82.7002 100.408 85.3594 94.4011 81.5013 91.2255Z"
      fill="url(#paint4_radial_3886_28276)"
    />
    <path
      d="M73.1542 86.7593C72.2547 86.2556 72.1418 85.156 73.0354 84.9784C73.7977 84.8268 74.6058 84.97 75.2804 85.408C76.4165 86.1454 76.7101 87.9939 76.1709 89.1139C76.0566 89.3514 75.7436 89.3361 75.5861 89.1247C74.917 88.2262 74.0537 87.2631 73.1542 86.7593Z"
      fill="url(#paint5_radial_3886_28276)"
    />
    <path
      d="M0.779471 79.0055H201.883V106.58C201.883 109.593 199.44 112.036 196.427 112.036H6.23575C3.22233 112.036 0.779471 109.593 0.779471 106.58V79.0055Z"
      stroke="#051851"
      strokeWidth="1.55894"
    />
    <path
      d="M0.779471 79.0055H201.883V106.58C201.883 109.593 199.44 112.036 196.427 112.036H6.23575C3.22233 112.036 0.779471 109.593 0.779471 106.58V79.0055Z"
      stroke="url(#paint6_linear_3886_28276)"
      strokeOpacity="0.7"
      strokeWidth="1.55894"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M199.457 39.3617L194.567 34.9321C193.823 34.2612 193.106 32.754 192.518 32.5335C192.013 32.3497 191.866 32.7816 192.527 33.5168C192.288 33.8201 191.443 32.9103 190.533 32.8C190 32.7356 190.239 33.8477 189.862 34.0406C189.127 34.4174 189.623 35.1894 190.395 35.5846C190.928 35.8603 191.489 36.6598 192.858 37.3491L196.608 41.2089L199.457 39.3617Z"
      fill="#F1B0AB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M182.373 43.446C182.818 43.2158 183.35 43.2158 183.795 43.446L189.885 46.5962C191.005 47.176 192.315 46.2396 192.101 45.0117L190.938 38.3395C190.853 37.8519 191.017 37.3543 191.377 37.009L196.304 32.2837C197.211 31.4141 196.71 29.899 195.457 29.7198L188.649 28.7464C188.151 28.6752 187.721 28.3677 187.499 27.9241L184.454 21.8535C183.893 20.7363 182.274 20.7363 181.714 21.8535L178.669 27.9241C178.447 28.3677 178.016 28.6752 177.519 28.7464L170.71 29.7198C169.457 29.899 168.957 31.4141 169.864 32.2837L174.79 37.009C175.15 37.3543 175.315 37.8519 175.23 38.3395L174.067 45.0117C173.853 46.2396 175.163 47.176 176.283 46.5962L182.373 43.446Z"
      fill="#00E5D0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.329 45.7647L184.239 42.6145C183.516 42.2404 182.652 42.2404 181.929 42.6145L175.839 45.7647C175.419 45.9821 174.927 45.631 175.008 45.1705L176.171 38.4983C176.309 37.7059 176.042 36.8974 175.457 36.3363L170.53 31.611C170.19 31.2849 170.378 30.7167 170.848 30.6495L177.656 29.6761C178.465 29.5605 179.164 29.0608 179.525 28.3399L182.57 22.2693C182.78 21.8503 183.387 21.8503 183.598 22.2693L186.642 28.3399C187.004 29.0608 187.703 29.5605 188.512 29.6761L195.32 30.6495C195.79 30.7167 195.978 31.2849 195.638 31.611L190.711 36.3363C190.126 36.8974 189.859 37.7059 189.997 38.4983L191.16 45.1705C191.24 45.631 190.749 45.9821 190.329 45.7647ZM183.795 43.446C183.35 43.2158 182.818 43.2158 182.373 43.446L176.283 46.5962C175.163 47.176 173.853 46.2396 174.067 45.0117L175.23 38.3395C175.315 37.8519 175.15 37.3543 174.79 37.009L169.864 32.2837C168.957 31.4141 169.457 29.899 170.71 29.7198L177.519 28.7464C178.016 28.6752 178.447 28.3677 178.669 27.9241L181.714 21.8535C182.274 20.7363 183.893 20.7363 184.454 21.8535L187.499 27.9241C187.721 28.3677 188.151 28.6752 188.649 28.7464L195.457 29.7198C196.71 29.899 197.211 31.4141 196.304 32.2837L191.377 37.009C191.017 37.3543 190.853 37.8519 190.938 38.3395L192.101 45.0117C192.315 46.2396 191.005 47.176 189.885 46.5962L183.795 43.446Z"
      fill="#008579"
      fillOpacity="0.6"
    />
    <path
      d="M199.567 75.3503C199.567 75.3503 199.19 78.2452 200.026 80.8001C200.026 80.8001 203.041 97.3606 200.495 98.8678C198.501 100.375 197.288 100.54 197.251 101.303C197.214 102.066 203.895 99.3916 203.895 99.3916C203.895 99.3916 204.355 98.1694 204.042 96.5887C203.73 95.008 205.246 81.6915 205.246 81.6915L207.001 76.3521L199.567 75.3503Z"
      fill="#F9B995"
    />
    <path
      d="M197.94 100.43C198.023 100.403 198.528 100.375 199.015 100.357C199.631 100.329 200.219 100.127 200.716 99.7595C201.846 98.9324 203.877 97.5079 204.245 97.7653C204.245 97.7653 204.741 97.9491 204.392 99.1162L204.649 101.478L203.877 101.515L203.767 100.458C203.767 100.458 202.673 99.7963 201.791 100.476C201.276 100.872 200.89 101.221 200.67 101.441C200.504 101.607 200.284 101.699 200.054 101.708L197.444 101.846C197.435 101.864 195.523 101.368 197.94 100.43Z"
      fill="#232021"
    />
    <path
      d="M200.402 73.375C200.402 73.375 213.903 95.1004 211.568 97.4715C210.649 99.6679 209.85 100.274 210.41 101.01C210.971 101.736 215.566 95.5324 215.566 95.5324C215.18 96.1573 214.748 95.1004 214.068 94.0068C214.068 94.0068 210.732 86.572 209.739 82.2711C208.747 77.9701 206.881 72.4009 206.881 72.4009L200.402 73.375Z"
      fill="#F9B995"
    />
    <path
      d="M210.475 100.1C210.521 100.044 210.879 99.879 211.256 99.6952C211.927 99.3827 212.46 98.8497 212.763 98.1788C213.333 96.9382 214.289 95.0358 214.757 95.1277C214.757 95.1277 215.336 95.1185 215.713 96.3684L217.395 98.5373L216.696 98.8773L215.952 97.9123C215.952 97.9123 214.527 97.6918 214.114 98.6843C213.866 99.2817 213.719 99.7687 213.645 100.063C213.59 100.265 213.452 100.43 213.268 100.522L210.879 101.671C210.87 101.662 208.774 101.919 210.475 100.1Z"
      fill="#232021"
    />
    <path
      d="M209.814 63.8906C210.329 65.0394 210.402 65.9676 210.365 66.5925C210.319 67.5207 210.044 67.8975 209.888 69.5885C209.805 70.434 209.823 70.9211 209.823 71.0865C209.86 72.0422 210.016 72.7591 210.126 73.1818C210.889 76.3248 211.606 76.8487 211.386 78.0985C211.183 79.2932 210.31 80.056 209.888 80.442C208.491 81.6827 206.855 81.747 204.07 81.8205C201.506 81.8848 200.091 81.9032 199.273 80.9199C198.859 80.4236 198.758 79.6884 198.556 78.218C198.363 76.7935 198.391 75.8653 198.372 74.6155C198.363 73.9446 198.354 74.2663 198.133 70.241C198.06 68.8717 197.95 66.905 197.83 64.4788L209.814 63.8906Z"
      fill="#A4556B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M189.137 46.1906L184.248 41.7609C183.504 41.0901 182.787 39.5829 182.199 39.3623C181.693 39.1785 181.546 39.6104 182.208 40.3457C181.969 40.6489 181.123 39.7391 180.214 39.6288C179.681 39.5645 179.92 40.6765 179.543 40.8695C178.808 41.2463 179.304 42.0183 180.076 42.4134C180.609 42.6891 181.169 43.4887 182.539 44.1779L186.288 48.0378L189.137 46.1906Z"
      fill="#F9B995"
    />
    <path
      d="M200.697 45.7581C200.44 44.591 199.87 44.1866 199.87 44.1866L193.979 38.7185L196.341 36.4578L208.242 46.3187H208.224C209.317 47.1458 208.968 50.3348 208.968 50.3348C208.968 50.3348 209.281 56.1522 209.584 57.3561C209.887 58.56 210.181 63.6513 210.181 63.6513C209.611 64.0281 206.367 66.1142 203.105 65.9304C202.673 65.9028 201.754 65.7099 199.925 65.3331C199.585 65.2595 199.3 65.2044 199.061 65.1401C198.445 64.9838 198.105 64.8184 197.83 64.4968C197.692 64.3405 197.444 63.9913 197.232 62.6404C197.058 61.5559 197.058 60.7472 197.048 60.3612C197.012 58.4772 196.672 57.0068 196.451 56.0419C196.424 55.904 196.387 55.757 196.341 55.6099C196.139 54.8196 195.817 53.7903 195.312 52.6232C195.312 52.6232 201.055 47.3664 200.697 45.7581Z"
      fill="#FAFAEF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M199.871 44.1869C199.871 44.1869 200.44 44.6004 200.698 45.7584C201.056 47.3667 195.312 52.6142 195.312 52.6142C195.312 52.6142 193.915 54.1214 192.362 54.1214C190.818 54.1214 182.327 44.5637 182.327 44.1869C182.327 43.8101 183.907 41.7791 184.569 42.018C185.231 42.257 192.362 48.543 192.362 48.543C192.362 48.543 197.251 42.8911 199.871 44.1869Z"
      fill="#FAFAEF"
    />
    <path d="M195.835 51.7682L197.618 50.2702L199.401 48.7722L197.618 50.261L195.835 51.7682Z" fill="white" />
    <path d="M204.804 45.2256L206.128 45.7678L207.451 46.3008L206.128 45.7586L204.804 45.2256Z" fill="white" />
    <path
      d="M198.307 44.8208C198.546 44.83 198.794 44.8943 199.006 44.9954C199.226 45.0965 199.419 45.2527 199.566 45.4457C199.713 45.6387 199.823 45.8593 199.888 46.0982C199.952 46.3372 199.97 46.5761 199.952 46.8242C199.97 46.5853 199.952 46.3372 199.897 46.0982C199.833 45.8593 199.732 45.6387 199.584 45.4457C199.428 45.2527 199.235 45.0965 199.015 44.9954C198.794 44.8851 198.555 44.83 198.307 44.8208Z"
      fill="white"
    />
    <path
      d="M192.277 49.398C192.35 49.1682 192.369 48.9201 192.323 48.6812C192.35 48.9201 192.341 49.1682 192.277 49.398Z"
      fill="white"
    />
    <path
      d="M205.238 81.7929C205.238 81.7929 207.104 78.5212 207.003 77.2806C206.901 76.0399 208.087 79.0451 205.238 81.7929Z"
      fill="#844457"
    />
    <path
      d="M199.31 37.4503C199.338 37.4411 199.356 37.4319 199.384 37.4227L200.799 34.9873C201.461 33.8477 203.097 33.5537 204.439 34.3256L204.494 34.3624C205.835 35.1435 206.387 36.7059 205.725 37.8454L204.972 39.1412C205.073 39.178 205.174 39.224 205.256 39.3067C205.523 39.5824 205.404 39.9867 205.312 40.29C205.256 40.483 205.146 40.8506 204.76 41.0804C204.622 41.1631 204.264 41.3744 203.86 41.2182C203.832 41.209 203.814 41.1906 203.786 41.1814C203.124 42.321 201.489 42.6151 200.147 41.8431L200.092 41.8064C198.75 41.0252 198.198 39.4629 198.86 38.3233L199.053 37.9925C199.044 37.9833 199.035 37.9833 199.035 37.9741C199.016 37.9557 198.86 37.7995 198.897 37.6708C198.934 37.5238 199.127 37.5054 199.31 37.4503Z"
      fill="#F9B995"
    />
    <path
      d="M201.994 42.2476L201.057 44.2143C201.057 44.2143 202.417 46.0982 204.043 45.4549L205.192 42.7438C205.192 42.7438 204.558 40.6669 204.429 40.768C204.301 40.8599 201.994 42.2476 201.994 42.2476Z"
      fill="#F9B995"
    />
    <path
      d="M204.973 39.1326C204.789 39.004 205.01 38.4066 205.065 38.1768C205.267 37.3314 204.44 36.4123 203.98 35.9069C202.924 34.7397 202.198 35.0154 201.784 34.1056C201.517 33.5267 201.527 32.7914 201.839 32.1757C201.876 32.093 202.299 31.3118 203.144 31.0913C204.1 30.8431 205.23 31.4037 205.828 31.6978C205.901 31.7346 206.067 31.8173 206.306 31.8908C206.82 32.0379 207.234 31.9919 207.362 31.9827C208.281 31.9276 209.375 33.1131 209.632 33.3888C210.772 34.6203 210.974 36.0815 211.038 36.6145C211.084 36.9821 211.323 39.2245 209.825 41.2096C209.347 41.8437 208.465 42.3767 206.701 43.4428C206.223 43.7277 205.671 44.0401 205.01 43.9207C204.863 43.8931 204.311 43.8104 204.017 43.3784C203.549 42.6708 204.054 41.4669 204.651 41.1361C204.716 41.0993 204.808 41.0625 204.918 40.9706C205.194 40.7409 205.276 40.4468 205.387 40.07C205.414 39.9689 205.46 39.8219 205.432 39.6381C205.423 39.6013 205.423 39.5462 205.387 39.4726C205.313 39.298 205.175 39.2061 205.129 39.1786C205.037 39.151 205.01 39.1602 204.973 39.1326Z"
      fill="#402840"
    />
    <path
      d="M210.487 35.447C211.268 35.1805 212.132 35.7043 212.491 35.9157C212.776 36.0903 213.695 36.6417 213.98 37.7905C214.007 37.9099 214.356 39.4171 213.253 40.474C212.592 41.1081 211.673 41.3195 210.873 41.154C210.708 41.1173 210.129 40.9978 209.679 40.5567C208.401 39.2701 208.971 35.9708 210.487 35.447Z"
      fill="#402840"
    />
    <path
      d="M201.995 42.2476C201.995 42.2476 202.464 42.2017 202.721 42.073C202.978 41.9535 201.811 42.6428 201.811 42.6428L201.995 42.2476Z"
      fill="#D99873"
    />
    <path
      d="M204.926 39.3523C204.972 39.4074 205.028 39.4993 205.055 39.6096C205.138 39.8761 205.046 40.1151 205.009 40.1978"
      fill="#D99873"
    />
    <path
      d="M195.331 51.8054C195.331 51.8054 197.196 48.5337 197.095 47.293C196.994 46.0523 198.179 49.0575 195.331 51.8054Z"
      fill="#D1D1D1"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3886_28276"
        x1="-1.50995e-06"
        y1="56.3346"
        x2="202.662"
        y2="56.3346"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#051851" />
        <stop offset="1" stopColor="#2B59E0" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_3886_28276"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(77.1313 93.1867) rotate(-33.658) scale(13.6699 13.7808)"
      >
        <stop offset="0.536654" stopColor="#1CDFCD" />
        <stop offset="1" stopColor="#00AA9C" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_3886_28276"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(76.3035 91.1529) rotate(178.58) scale(10.8482 10.9362)"
      >
        <stop stopColor="#1DDFCE" />
        <stop offset="0.992249" stopColor="#2B5AE0" />
      </radialGradient>
      <linearGradient
        id="paint3_linear_3886_28276"
        x1="74.6641"
        y1="102.916"
        x2="68.9016"
        y2="97.228"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2B59E0" />
        <stop offset="1" stopColor="#071037" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint4_radial_3886_28276"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(83.4295 99.1851) rotate(-151.073) scale(15.3802 15.505)"
      >
        <stop offset="0.08836" stopColor="#2B59E0" />
        <stop offset="0.796792" stopColor="#1DDFCE" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_3886_28276"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(73.5069 86.5468) rotate(33.1769) scale(4.91672 4.7559)"
      >
        <stop offset="0.192111" stopColor="#2B59E0" />
        <stop offset="0.793069" stopColor="#1DDFCE" />
      </radialGradient>
      <linearGradient
        id="paint6_linear_3886_28276"
        x1="-1.50995e-06"
        y1="95.5208"
        x2="202.662"
        y2="95.5208"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#051851" />
        <stop offset="1" stopColor="#2B59E0" />
      </linearGradient>
    </defs>
  </svg>
)

export default NoReviewsImageIcon
