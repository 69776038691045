import { styledComponent, Box, Grid } from '@hermes/web-components'

export const MediaCenterHeader = styledComponent(Box)(({ theme }) => ({
  padding: '140px 0 85px 0',
  position: 'relative',
  zIndex: 1,
  [theme.breakpoints.down('sm')]: {
    padding: '70px 0 55px 0'
  }
}))

export const MediaContentWrapper = styledComponent(Box)({
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '1014px',
  paddingLeft: '16px',
  paddingRight: '16px'
})

export const AvatarWrapper = styledComponent(Grid)({
  position: 'relative',
  '&::before': {
    position: 'absolute',
    top: -30,
    left: -30,
    width: 48,
    height: 50,
    zIndex: 1,
    content:
      "url(\"data:image/svg+xml,%3Csvg width='48' height='50' viewBox='0 0 48 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M45.8066 15.8582L45.8066 1.99995' stroke='%23406AE3' stroke-width='4' stroke-linecap='round'/%3E%3Cpath d='M20.918 24.9226L9.15889 13.1635' stroke='%23406AE3' stroke-width='4' stroke-linecap='round'/%3E%3Cpath d='M16.3584 47.7322L2.5002 47.7322' stroke='%23406AE3' stroke-width='4' stroke-linecap='round'/%3E%3C/svg%3E%0A\")" // eslint-disable-line
  }
})

export const GetToKnowWrapper = styledComponent(Grid, {
  shouldForwardProp: (prop) => prop !== 'nameLength'
})<{
  nameLength?: number
  component: string
}>(({ theme, nameLength = 0 }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  color: theme.palette.common.white,
  '& > span:first-of-type': {
    fontSize: '25px',
    lineHeight: '27.5px'
  },
  '& > span:last-of-type': {
    fontSize: '45px',
    fontWeight: 600,
    lineHeight: '49.5px'
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'start',
    '& > span:first-of-type': {
      fontSize: '16px',
      lineHeight: '22.4px'
    },
    '& > span:last-of-type': {
      fontSize: nameLength > 28 ? '16px' : '24px',
      lineHeight: nameLength > 28 ? '22.03px' : '31.2px'
    }
  }
}))

export const NavigationButtonsWrapper = styledComponent(Grid)({
  display: 'flex',
  gap: '13px',
  '@media (min-width: 420px)': {
    alignItems: 'end',
    '.MuiChip-root': {
      padding: 0,
      borderRadius: '45px',
      height: 'auto',
      span: {
        padding: '8px 24px'
      }
    }
  }
})

export const MediaHeader = styledComponent(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    gap: '37px',
    flexDirection: 'column'
  }
}))

export const HeaderSpecialistInfo = styledComponent(Grid)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 32
  }
}))
