import React from 'react'

const TrustLarge = () => (
  <svg width="34" height="37" viewBox="0 0 34 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.05277 23.7369C6.05277 23.7369 3.52645 25.4211 3.52645 28.1081C3.52645 30.7951 1.00014 32.4793 1.00014 32.4793L7.28786 31.7015L9.84224 36.3685C9.84224 36.3685 11.6949 35.9475 12.0317 33.6317C12.3686 31.3159 13.6317 30.8948 13.6317 30.8948"
      fill="#B4FFFB"
    />
    <path
      d="M6.05277 23.7369C6.05277 23.7369 3.52645 25.4211 3.52645 28.1081C3.52645 30.7951 1.00014 32.4793 1.00014 32.4793L7.28786 31.7015L9.84224 36.3685C9.84224 36.3685 11.6949 35.9475 12.0317 33.6317C12.3686 31.3159 13.6317 30.8948 13.6317 30.8948"
      stroke="#2B59E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.9472 23.7369C27.9472 23.7369 30.4735 25.4211 30.4735 28.1081C30.4735 30.7951 32.9999 32.4793 32.9999 32.4793L26.7121 31.7015L24.1578 36.3685C24.1578 36.3685 22.3051 35.9475 21.9683 33.6317C21.6314 31.3159 20.3683 30.8948 20.3683 30.8948"
      fill="#B4FFFB"
    />
    <path
      d="M27.9472 23.7369C27.9472 23.7369 30.4735 25.4211 30.4735 28.1081C30.4735 30.7951 32.9999 32.4793 32.9999 32.4793L26.7121 31.7015L24.1578 36.3685C24.1578 36.3685 22.3051 35.9475 21.9683 33.6317C21.6314 31.3159 20.3683 30.8948 20.3683 30.8948"
      stroke="#2B59E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.6314 17.6659C29.6314 27.8103 16.9999 32.1579 16.9999 32.1579C16.9999 32.1579 4.36829 27.8103 4.36829 17.6659V3.89841L16.9999 1L29.6314 3.89841V17.6659Z"
      fill="url(#paint0_radial_21_1948)"
      stroke="#2B59E0"
    />
    <path
      d="M10.2631 16.9999L15.3157 22.0525L25.421 10.2631"
      stroke="#2B59E0"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <radialGradient
        id="paint0_radial_21_1948"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(46.4735 32.1579) rotate(-147.381) scale(49.9901 75.7171)"
      >
        <stop offset="0.197917" stopColor="#2B59E0" />
        <stop offset="0.802083" stopColor="#B4FFFB" />
      </radialGradient>
    </defs>
  </svg>
)

export default TrustLarge
