import React, { FC } from 'react'
import '@splidejs/react-splide/css'
import { Button, Carousel, Grid, Typography, theme, useMediaQuery, Link } from '@hermes/web-components'
import { SplideSlide } from '@splidejs/react-splide'
import { SpecialistProfile } from '../../../types/specialist'
import SpecialistCard from './OtherSpecialists/SpecialistCard'

type OtherTopRatedProps = {
  specialists?: SpecialistProfile[]
  headerText?: string
  buttonText?: string
  shortButtonText?: string
  buttonLink?: string
  isRtl?: boolean
}

function NavButton({ text, href }: { text: string; href: string }) {
  return (
    <Button
      variant="outlined"
      component={Link}
      href={href}
      sx={{
        borderRadius: '24px',
        padding: '8px 24px 8px 24px'
      }}
    >
      {text}
    </Button>
  )
}

const OtherTopRated: FC<OtherTopRatedProps> = ({
  specialists,
  headerText,
  buttonText,
  shortButtonText,
  buttonLink,
  isRtl
}) => {
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const buttonTextContent = isSmDown ? shortButtonText : buttonText

  const NavigationButton = <NavButton text={buttonTextContent || ''} href={buttonLink || ''} />

  if (!specialists?.length) {
    return null
  }
  return (
    <Grid container>
      <Typography variant="body2" textTransform="uppercase" sx={{ mb: 2 }} color="text.darkNavy" fontWeight="700">
        {headerText}
      </Typography>
      <Carousel
        breakpoints={{
          610: {
            perPage: 1
          }
        }}
        spaceBetween={16}
        navigationElement={NavigationButton}
        slidesPerView={2}
        slidesPerGroup={1}
        isRtl={isRtl}
        vertical={false}
        navigation={true}
        navigationPosition="bottom"
      >
        {specialists.map((s) => (
          <SplideSlide key={`other-specialist-${s.id}`}>
            <Grid sx={{ pb: 2, height: '100%' }}>
              <SpecialistCard specialist={s} />
            </Grid>
          </SplideSlide>
        ))}
      </Carousel>
    </Grid>
  )
}

export default OtherTopRated
