import React, { RefObject, useEffect, useMemo, useRef } from 'react'
import { Grid, SxProps } from '@mui/material'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { type BoxProps } from '../Box'
import { NavigationContainer, NavButton, StyledGridSlide } from './styles'
import CarouselArrow from '../../icons/CarouselArrow'
import { styledComponent } from '../../styles/styled'
import { theme as muiTheme } from '../../theme'

export const CarouselSlide = styledComponent(SplideSlide)({})
export const GridSlide = (props: BoxProps) => <StyledGridSlide {...props} />

export const CarouselLazyImage = ({
  src,
  alt,
  ...props
}: {
  src: string
  alt: string
  width?: string | number
  height?: string | number
  style?: React.CSSProperties
}) => <img data-splide-lazy={src} alt={alt} {...props} />

export const CarouselNavButton = ({
  onClick,
  type,
  refObj
}: {
  onClick: () => void
  type: 'next' | 'prev'
  refObj?: RefObject<HTMLButtonElement>
}) => (
  <NavButton
    ref={refObj}
    sx={{ backgroundColor: muiTheme.palette.background.default }}
    onClick={onClick}
    aria-label={`carousel-navigation-${type}`}
  >
    {type === 'next' && <CarouselArrow />}
    {type === 'prev' && <CarouselArrow rotation={180} />}
  </NavButton>
)

interface CarouselProps {
  children: React.ReactNode
  slidesPerView: number
  breakpoints?: {
    [key: number]: {
      perPage: number
    }
  }
  navigation?: boolean
  navigationPosition?: 'bottom' | 'laterally' | 'inside'
  navigationElement?: React.ReactNode
  pagination?: boolean
  spaceBetween?: number
  isRtl?: boolean
  slidesPerGroup?: number
  loop?: boolean
  autoPlay?: boolean
  speed?: 'slower' | 'faster'
  sx?: SxProps
  updateOnMove?: boolean
  vertical?: boolean
  currentSlide?: number
  verticalHeight?: number
  trimSpace?: boolean
  centerFocus?: boolean
  slidePadding?: number
  wheel?: boolean
  lazyLoad?: boolean
  disableDrag?: boolean
}

const Carousel = ({
  children,
  slidesPerView = 3,
  navigation = false,
  navigationPosition = 'laterally',
  navigationElement = null,
  pagination = false,
  isRtl = false,
  spaceBetween,
  slidesPerGroup = 1,
  updateOnMove = false,
  loop = false,
  vertical = false,
  currentSlide = 0,
  verticalHeight,
  trimSpace = false,
  centerFocus = false,
  slidePadding = 0,
  wheel = false,
  lazyLoad = false,
  sx = {},
  breakpoints = {},
  disableDrag = false
}: CarouselProps) => {
  const splide = useRef<Splide>(null)
  const prevRef = useRef<HTMLButtonElement>(null)
  const nextRef = useRef<HTMLButtonElement>(null)
  const [perPage, setPerPage] = React.useState<number>(slidesPerView)

  useEffect(() => {
    if (splide.current) {
      splide.current.go(currentSlide)
    }
  }, [currentSlide, splide])

  const handleNext = () => {
    splide.current?.go('+1')
  }
  const handlePrev = () => splide.current?.go('-1')

  const directionScroll = useMemo(() => (isRtl ? 'rtl' : 'ltr'), [isRtl])

  return (
    <Grid md={12} lg={12} xs={12} sx={sx}>
      <Splide
        ref={splide}
        options={{
          ...(vertical
            ? {
                direction: 'ttb',
                height: `${verticalHeight}px`,
                trimSpace,
                focus: centerFocus ? 'center' : undefined
              }
            : {}),
          type: loop ? 'loop' : 'slide',
          direction: directionScroll,
          paginationDirection: directionScroll,
          perPage: slidesPerView,
          perMove: slidesPerGroup,
          pagination,
          arrows: false,
          gap: spaceBetween,
          updateOnMove,
          padding: `${slidePadding}rem`,
          wheel,
          drag: !disableDrag,
          breakpoints,
          ...(lazyLoad
            ? {
                lazyLoad: 'nearby'
              }
            : {})
        }}
        onMounted={(e) => {
          setPerPage(e?.options.perPage || slidesPerView)
        }}
      >
        {children}
      </Splide>
      <Grid sx={{ display: 'flex', gap: '16px', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        {navigationElement}
        {navigation && React.Children.count(children) > perPage && (
          <NavigationContainer navigationPosition={navigationPosition}>
            <CarouselNavButton refObj={prevRef} onClick={handlePrev} type={isRtl ? 'next' : 'prev'} />
            <CarouselNavButton refObj={nextRef} onClick={handleNext} type={isRtl ? 'prev' : 'next'} />
          </NavigationContainer>
        )}
      </Grid>
    </Grid>
  )
}

export { Carousel, type CarouselProps }
