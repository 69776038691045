import { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'

export default function useTrackWindowHeight(disabled = false): string {
  const [height, setHeight] = useState<string>('100vh')

  useEffect(() => {
    if (isMobile) {
      return
    }
    const resizeObserver = new ResizeObserver((entries) => {
      const footer = window.document.querySelector('footer')
      const footerHeight = footer?.offsetHeight || 0
      entries.forEach((el) => setHeight(`${el.contentRect.height - 104 - footerHeight}px`))
    })
    resizeObserver.observe(document.body)
  }, [disabled])

  return height
}
