import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

const UserIcon = (props: SvgIconProps) => (
  <SvgIcon width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.5588 21.8975C17.3578 21.8975 22.0588 17.1965 22.0588 11.3975C22.0588 5.59847 17.3578 0.897461 11.5588 0.897461C5.75985 0.897461 1.05884 5.59847 1.05884 11.3975C1.05884 17.1965 5.75985 21.8975 11.5588 21.8975Z"
      fill="#B4FFFB"
    />
    <path
      d="M11.5588 21.8975C17.3578 21.8975 22.0588 17.1965 22.0588 11.3975C22.0588 5.59847 17.3578 0.897461 11.5588 0.897461C5.75985 0.897461 1.05884 5.59847 1.05884 11.3975C1.05884 17.1965 5.75985 21.8975 11.5588 21.8975Z"
      fill="url(#paint0_linear_6164_3388)"
      fillOpacity="0.7"
    />
    <path
      d="M11.5588 21.8975C17.3578 21.8975 22.0588 17.1965 22.0588 11.3975C22.0588 5.59847 17.3578 0.897461 11.5588 0.897461C5.75985 0.897461 1.05884 5.59847 1.05884 11.3975C1.05884 17.1965 5.75985 21.8975 11.5588 21.8975Z"
      stroke="#001552"
      strokeMiterlimit="10"
      strokeLinecap="round"
      fill="transparent"
      strokeLinejoin="round"
    />
    <path
      d="M11.5587 15.2156C9.45007 15.2156 7.74048 13.506 7.74048 11.3974V9.48835C7.74048 7.37976 9.45007 5.67017 11.5587 5.67017C13.6673 5.67017 15.3768 7.37976 15.3768 9.48835V11.3974C15.3768 13.506 13.6673 15.2156 11.5587 15.2156Z"
      fill="white"
      stroke="#001552"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2863 19.9884C16.2532 18.2683 14.685 17.1248 12.9657 17.1248H10.1495C8.41563 17.1248 6.86483 18.2344 5.83174 19.9797"
      stroke="#001552"
      fill="transparent"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6164_3388"
        x1="1.05884"
        y1="11.3975"
        x2="22.0588"
        y2="11.3975"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B4FFFB" />
        <stop offset="0.9999" stopColor="#00E5D0" />
        <stop offset="1" stopColor="#00E5D0" />
      </linearGradient>
    </defs>
  </SvgIcon>
)

export default UserIcon
